import { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, TextField } from "@material-ui/core";
import instance from "../instance";
import logo from "../image/logo.png";
import { fontWeight } from "@mui/system";
export const Form = (props) => {
 
  const baseURL = process.env.REACT_APP_BACKEND_API;
  const [studentid , setStudentId] = useState("");

  const [isShow, setIsShow] = useState(false);
  // const [isError, setIsError] = useState(false);
  // const [selecteddate, setSelectedDate] = useState();
  const [studentinfo, setStudentInfo] = useState({});
  // const handleDateChange = (date) => {
  //   //console.log(date);
  //   setSelectedDate(date);
  // };
  
  const reset = () => {setStudentId("");setIsShow(false);setStudentInfo({});setValue('studentid','')};

const { formState, getValues, watch, register, setValue, handleSubmit } =
useForm({});
const { errors } = formState;
const onSubmit = async (data) => {
   
    setStudentInfo({});
    setIsShow(false);
    var finaldata = {
      studentid: data.studentid
    };
    // console.log(
    //   finaldata,
    //   finaldata.dateofbirth !=
    //     moment(new Date())
    //       .utc(+6)
    //       .format("yyyy-MM-DD")
    // );
    
     
      await instance
        .post(`Common/GetStudentByIDForVerify/${finaldata.studentid}`)
        .then((res) => {
          setIsShow(true);
          setStudentInfo(res.data.res);
          
        })
        .catch((err) => {
          console.log(err);
        });
    

    // emailjs
    //   .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       clearState();
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
  };
  const twoDigit=(num)=>{
    return (num).toFixed(2);
  }
        
        
  return (
    <div>
      <div id="verify">
        <div className="container">
        <div className="row">
          <div className="col-md-6">
            
              <div className="section-title">
                <h2>Verify</h2>
                <p>
                  Please fill out the form with valid Student ID.
                </p>
                {/* <hr style={{backgroundColor:"white", width:"500px",marginLeft:"0"}}/> */}
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-md-6" style={{ margin: "10px" }}>
                    <div className="form-group">
                    <TextField
                      type="text"
                      fullWidth
                      label="Student ID"
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold",color:'whitesmoke' },
                        
                      }}
                      InputLabelProps={{
                        style: { fontSize: 15, fontWeight: "bold",color:'whitesmoke' },
                      }}
                      inputRef={register({
                        required: true,
                      })}
                      name="studentid"
                      variant="outlined"
                      className="input-field"
                      onChange={(e) => setStudentId(e.target.value)}
                    />
                    <Box>
                      {errors.studentid && (
                        <span style={{ color: "white" }}>
                          {" "}
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          Student Id is required
                        </span>
                      )}
                    </Box>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-6" style={{ margin: "10px" }}>
                    <div className="form-group">
                      <MuiPickersUtilsProvider
                        locale={enUS}
                        utils={DateFnsUtils}
                      >
                        <KeyboardDatePicker
                          fullWidth
                          format="dd/MM/yyyy"
                          label="Date of Birth"
                          value={selecteddate}
                          onChange={handleDateChange}
                          inputProps={{
                            style: { fontSize: 16, fontWeight: "bold" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: 16, fontWeight: "bold" },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div> */}

                {/* <div>
                  {" "}
                  {isError ? (
                    <>
                      <p className="help-block text-light">
                        Date of Birth is required
                      </p>
                    </>
                  ) : null}
                </div> */}
                <button type="submit" className="btn btn-custom btn-lg">
                  Submit
                </button>
                <button type="button" className="btn btn-custom-reset btn-lg" onClick={(e)=>reset()}>
                  Reset
                </button>
              </form>
              <p id="blink" >Also, few transcript/certificates may not be verified via online. If information not found via online, please send a mail to <a style={{color:"yellow"}} href="https://mail.google.com/mail/?view=cm&fs=1&to=controller@primeasia.edu.bd" target="_blank"><b>controller@primeasia.edu.bd</b></a></p>
            </div>
          

          {isShow ? (
            <>
              {studentinfo != null ? (
                <div className="col-md-5 col-md-offset-1 contact-info ">
                  <div className="card-category-5">
                    <div className="category-name">Student's Information</div>{" "}
                    <br />
                    <br />
                    <ul className="all-pr-cards">
                      <li>
                        <div className="per-card-2">
                          <div className="card-image">
                            <ul>
                              <li>
                                <img
                                  src={`${baseURL}StudentInfo/GetImage/${studentid}`}
                                />
                              </li>
                              <li>
                                <div className="per-name text-center">
                                  {studentinfo ? studentinfo.studentname : ""}
                                </div>
                                <div className="per-position">
                                  {studentinfo ? studentinfo.studentid : ""}
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div className="card-content ">
                            <div className="card-text section-title">
                            <h6 style={{padding:'2px',color: "whitesmoke", background: "linear-gradient(111.3deg, #9c27b0 9.6%, #00bcd4 93.6%)" }}>
                                Department
                              </h6>
                              <h6 style={{ color: "whitesmoke" }}>
                                {studentinfo ? studentinfo.departmentname : ""}
                              </h6>
                              <h6 style={{padding:'2px',color: "whitesmoke", background: "linear-gradient(111.3deg, #9c27b0 9.6%, #00bcd4 93.6%)" }}>
                                CGPA
                              </h6>
                              <h6 style={{ color: "whitesmoke" }}>
                                {studentinfo.cgpa!=undefined ?  twoDigit(studentinfo.cgpa)   : ""}
                              </h6>
                              <h6 style={{padding:'2px',color: "whitesmoke", background: "linear-gradient(111.3deg, #9c27b0 9.6%, #00bcd4 93.6%)" }}>
                                Passing Year
                              </h6>
                              <h6 style={{ color: "whitesmoke" }}>
                                {studentinfo ?  studentinfo.graduateyear : ""}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="col-md-5 col-md-offset-1 contact-info">
                  <div className="card-category-5">
                    <div className="category-name">Student's Information</div>{" "}
                    <br />
                    <br />
                    <ul className="all-pr-cards">
                      <li>
                        <div className="per-card-2">
                          <div className="card-image">
                            <ul>
                              <li>
                                <div className="per-name" style={{fontSize:'30px'}}>Not Found</div>
                              </li>
                             
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </>
          ) :   <div className="col-md-5 col-md-offset-1 contact-info">
          <div className="card-category-5" style={{ paddingLeft:'50px'}}>
            
      
              
                  
          <img src={logo} className="img-responsive" alt="" />
                    
               
             
          </div>
          </div>
        }
        
        
        

        </div>
        </div>
        
      </div>
      <style jsx="true">
      
        {
        
        
        `
        
          @keyframes down-btn {
            0% {
              bottom: 20px;
            }
            100% {
              bottom: 0px;
            }

            0% {
              opacity: 0;
            }
            100% {
              opaicty: 1;
            }
          }

          @-webkit-keyframes down-btn {
            0% {
              bottom: 20px;
            }
            100% {
              bottom: 0px;
            }

            0% {
              opacity: 0;
            }
            100% {
              opaicty: 1;
            }
          }

          @-moz-keyframes down-btn {
            0% {
              bottom: 20px;
            }
            100% {
              bottom: 0px;
            }

            0% {
              opacity: 0;
            }
            100% {
              opaicty: 1;
            }
          }

          @-o-keyframes down-btn {
            0% {
              bottom: 20px;
            }
            100% {
              bottom: 0px;
            }

            0% {
              opacity: 0;
            }
            100% {
              opaicty: 1;
            }
          }

          body {
            margin: 0;
          }

          .category-name {
            font-family: sans-serif;
            width: -webkit-fill-available;
            text-align: center;
            font-size: 40px;
          }

          .card-category-2 ul,
          .card-category-3 ul,
          .card-category-4 ul,
          .card-category-5 ul .card-category-6 ul {
            padding: 0;
          }

          .card-category-2 ul li,
          .card-category-3 ul li,
          .card-category-4 ul li,
          .card-category-5 ul li,
          .card-category-6 ul li {
            list-style-type: none;
            display: inline-block;
            vertical-align: top;
          }

          .card-category-2 ul li,
          .card-category-3 ul li {
            margin: 10px 5px;
          }

          .card-category-1,
          .card-category-2,
          .card-category-3,
          .card-category-4,
          .card-category-5,
          .card-category-6 {
            font-family: sans-serif;
            margin-bottom: 45px;
            text-align: center;
          }
          .card-category-1 div,
          .card-category-2 div {
            display: inline-block;
          }

          .card-category-1 > div,
          .card-category-2 > div:not(:last-child) {
            margin: 10px 5px;
            text-align: left;
          }

          /* Basic Card */
          .basic-card {
            width: 300px;
            position: relative;

            -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
            -o-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
          }

          .basic-card .card-content {
            padding: 30px;
          }

          .basic-card .card-title {
            font-size: 25px;
            font-family: "Open Sans", sans-serif;
          }

          .basic-card .card-text {
            line-height: 1.6;
          }

          .basic-card .card-link {
            padding: 25px;
            width: -webkit-fill-available;
          }

          .basic-card .card-link a {
            text-decoration: none;
            position: relative;
            padding: 10px 0px;
          }

          .basic-card .card-link a:after {
            top: 30px;
            content: "";
            display: block;
            height: 2px;
            left: 50%;
            position: absolute;
            width: 0;

            -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
            -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
            -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
          }

          .basic-card .card-link a:hover:after {
            width: 100%;
            left: 0;
          }

          .basic-card-aqua {
            background-image: linear-gradient(
              to bottom right,
              #00bfad,
              #99a3d4
            );
          }

          .basic-card-aqua .card-content,
          .basic-card .card-link a {
            color: #fff;
          }

          .basic-card-aqua .card-link {
            border-top: 1px solid #82c1bb;
          }

          .basic-card-aqua .card-link a:after {
            background: #fff;
          }

          .basic-card-lips {
            background-image: linear-gradient(
              to bottom right,
              #ec407b,
              #ff7d94
            );
          }

          .basic-card-lips .card-content {
            color: #fff;
          }

          .basic-card-lips .card-link {
            border-top: 1px solid #ff97ba;
          }

          .basic-card-lips .card-link a:after {
            background: #fff;
          }

          .basic-card-light {
            border: 1px solid #eee;
          }

          .basic-card-light .card-title,
          .basic-card-light .card-link a {
            color: #636363;
          }

          .basic-card-light .card-text {
            color: #7b7b7b;
          }

          .basic-card-light .card-link {
            border-top: 1px solid #eee;
          }

          .basic-card-light .card-link a:after {
            background: #636363;
          }

          .basic-card-dark {
            background-image: linear-gradient(
              to bottom right,
              #252525,
              #4a4a4a
            );
          }

          .basic-card-dark .card-title,
          .basic-card-dark .card-link a {
            color: #eee;
          }

          .basic-card-dark .card-text {
            color: #dcdcdcdd;
          }

          .basic-card-dark .card-link {
            border-top: 1px solid #636363;
          }

          .basic-card-dark .card-link a:after {
            background: #eee;
          }

          /* Image Card */
          .img-card {
            width: 300px;
            position: relative;
            border-radius: 5px;
            text-align: left;

            -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
            -o-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
          }

          .img-card .card-image {
            position: relative;
            margin: auto;
            overflow: hidden;
            border-radius: 5px 5px 0px 0px;
            height: 200px;
          }

          .img-card .card-image img {
            width: 100%;
            border-radius: 5px 5px 0px 0px;

            -webkit-transition: all 0.8s;
            -moz-transition: all 0.8s;
            -o-transition: all 0.8s;
            transition: all 0.8s;
          }

          .img-card .card-image:hover img {
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -o-transform: scale(1.1);
            transform: scale(1.1);
          }

          .img-card .card-text {
            padding: 0 15px 15px;
            line-height: 1.5;
          }

          .img-card .card-link {
            padding: 20px 15px 30px;
            width: -webkit-fill-available;
          }

          .img-card .card-link a {
            text-decoration: none;
            position: relative;
            padding: 10px 0;
          }

          .img-card .card-link a:after {
            top: 30px;
            content: "";
            display: block;
            height: 2px;
            left: 50%;
            position: absolute;
            width: 0;

            -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
            -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
            -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
          }

          .img-card .card-link a:hover:after {
            width: 100%;
            left: 0;
          }

          .img-card.iCard-style1 .card-title {
            position: absolute;
            font-family: "Open Sans", sans-serif;
            z-index: 1;
            top: 10px;
            left: 10px;
            font-size: 30px;
            color: #fff;
          }

          .img-card.iCard-style1 .card-text {
            color: #795548;
          }

          .img-card.iCard-style1 .card-link a {
            color: #ff9800;
          }

          .img-card.iCard-style1 .card-link a:after {
            background: #ff9800;
          }

          .img-card.iCard-style2 .card-title {
            padding: 15px;
            font-size: 25px;
            font-family: "Roboto", sans-serif;
          }

          .img-card.iCard-style2 .card-image {
            margin-bottom: 15px;
          }

          .img-card.iCard-style2 .card-caption {
            text-align: center;
            top: 80%;
            font-size: 17px;
            color: #fff;
            position: absolute;
            width: 100%;
            font-family: "Roboto", sans-serif;
            z-index: 1;
          }

          .img-card.iCard-style2 .card-link a {
            border: 1px solid;
            padding: 8px;
            border-radius: 3px;
            color: black;
            font-size: 13px;

            -webkit-transition: all 0.4s;
            -moz-transition: all 0.4s;
            -o-transition: all 0.4s;
            transition: all 0.4s;
          }

          .img-card.iCard-style2 .card-link a:hover {
            background: black;
          }

          .img-card.iCard-style2 .card-link a:hover span {
            color: #fff;
          }

          .img-card.iCard-style3 {
            text-align: center;
          }

          .img-card.iCard-style3 .card-title {
            top: 80%;
            font-size: 30px;
            color: #fff;
            position: absolute;
            width: 100%;
            font-family: "Roboto", sans-serif;
            z-index: 1;
          }

          .img-card.iCard-style3 .card-text {
            color: #636060;
          }

          .img-card.iCard-style3 .card-link {
            border-top: 1px solid #e8e8e8;
          }
          .img-card.iCard-style3 .card-link a {
            color: #585858;
          }
          .img-card.iCard-style3 .card-link a:after {
            background: #585858;
          }

          .img-card.iCard-style4 {
            text-align: right;
          }

          .img-card.iCard-style4 .card-caption {
            position: absolute;
            width: 100%;
            font-family: "Open Sans", sans-serif;
            z-index: 1;
            top: 10px;
            right: 10px;
            color: #fff;
          }

          .img-card.iCard-style4 .card-title {
            width: 100%;
            padding: 20px 0px 12px 0;
            color: #e91e63;
          }

          .img-card.iCard-style4 .card-title span {
            font-size: 25px;
            margin-right: 12px;
          }

          .img-card.iCard-style4 .toggle {
            cursor: pointer;
            padding: 8px 0px 13px 0px;
            width: 55px;
          }

          .img-card.iCard-style4 .toggle span,
          .img-card.iCard-style4 .toggle span:nth-child(2),
          .img-card.iCard-style4 .toggle span:nth-child(3) {
            position: absolute;
            width: 8px;
            height: 8px;
            background: #e91e63;
            cursor: pointer;
          }

          .img-card.iCard-style4 .toggle span:nth-child(1) {
            right: 15px;
          }

          .img-card.iCard-style4 .toggle span:nth-child(2) {
            right: 27px;
          }

          .img-card.iCard-style4 .toggle span:nth-child(3) {
            right: 39px;
          }

          .img-card.iCard-style4 .card-text {
            opacity: 0;
            line-height: 0;
            padding-bottom: 0;
            color: #4c4c4c;

            -webkit-transition: ease-in-out 0.5s;
            -moz-transition: ease-in-out 0.5s;
            -o-transition: ease-in-out 0.5s;
            transition: ease-in-out 0.5s;
          }

          .img-card.iCard-style4 .toggle.active ~ .card-text {
            opacity: 1;
            line-height: 1.5;
            padding-bottom: 10px;
          }

          .img-card.iCard-style4 .card-link {
            border-top: 1px solid #e8e8e8;
          }

          .img-card.iCard-style4 .card-link a {
            color: #e91e63;
          }
          .img-card.iCard-style4 .card-link a:after {
            background: #e91e63;
          }

          /* Overlay Image Card */

          .ioverlay-card {
            position: relative;
            text-align: left;
          }

          .ioverlay-card img {
            height: auto;
            width: 100%;
            border-radius: 4px;
          }

          .ioverlay-card .card-content {
            position: absolute;
            top: 25px;
            left: 20px;
          }

          .ioverlay-card .card-content .card-title {
            font-size: 25px;
          }

          .io-card-1 {
            color: #fff;
          }

          .io-card-1 .card-content .card-text {
            width: 95%;
            line-height: 1.5;
            padding-bottom: 10px;
          }

          .io-card-1 .card-link {
            position: absolute;
            top: 140px;
            left: 20px;
          }

          .io-card-1 .card-link a {
            color: #fff;
            text-decoration: none;
            padding: 10px;
            border: 1px solid;
            border-radius: 4px;
            background: #3f51b5;

            -webkit-transition: all 0.4s;
            -moz-transition: all 0.4s;
            -o-transition: all 0.4s;
            transition: all 0.4s;
          }

          .io-card-1 .card-link a:hover {
            color: #3f51b5;
            background: #fff;
            border-color: #fff;
          }

          .io-card-2 .card-content {
            color: #fff;
          }

          .io-card-2 .card-link {
            position: absolute;
            bottom: 30px;
            left: 20px;
          }

          .io-card-2 .card-link a {
            color: #fff;
            text-decoration: none;
            padding: 10px;
            border: 1px solid #fff;
            border-radius: 4px;

            -webkit-transition: all 0.4s;
            -moz-transition: all 0.4s;
            -o-transition: all 0.4s;
            transition: all 0.4s;
          }

          .io-card-2 .card-link a:hover {
            color: black;
            background-color: #fff;
          }

          .io-card-2 .card-content .card-text {
            line-height: 1.5;
            padding-bottom: 10px;
          }

         
         
          .card-category-5 .all-pr-cards {
            padding: 0;
          }

          .card-category-5 .per-card-1 {
            text-align: center;
            width: 250px;
            background-image: linear-gradient(#315386, #ffffff);

            -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          }

          .card-category-5 .per-card-1 .card-image {
            padding: 20px;
          }

          .card-category-5 .per-card-1 .card-image img {
            height: 200px;
          }

          .card-category-5 .per-card-1 .card-content .card-title {
            font-size: 20px;
            padding-bottom: 6px;
          }

          .card-category-5 .per-card-1 .card-content .per-position {
            color: #002255;
            padding-bottom: 10px;
          }

          .card-category-5 .per-card-1 .card-content .social-icons i {
            font-size: 30px;
            padding: 5px;

            -webkit-transition: all 0.2s ease-in;
            -moz-transition: all 0.2s ease-in;
            -o-transition: all 0.2s ease-in;
            transition: all 0.2s ease-in;
          }

          .card-category-5 .per-card-1 .card-content .social-icons i:hover {
            color: #383838;
          }

          .card-category-5 .per-card-1 .card-content .card-btn button {
            margin-top: 15px;
            width: 100%;
            padding: 15px;
            background: black;
            color: #fff;
            border: none;
            font-size: 18px;
            cursor: pointer;

            -webkit-transition: all 0.2s ease-in;
            -moz-transition: all 0.2s ease-in;
            -o-transition: all 0.2s ease-in;
            transition: all 0.2s ease-in;
          }

          .card-category-5 .per-card-1 .card-content .card-btn button:hover {
            background: #383838;
          }

          .card-category-5 .per-card-2 {
            width: 300px;
            padding: 30px 0 0 0;
            margin: 0 40px; /*You may have remove this CSS. I used this CSS for apply some margin from the card before*/

            -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          }

          .card-category-5 .per-card-2 .card-image {
            padding: 10px 15px;
            background-image: linear-gradient(#3f51b5, #673ab7);
            width: 319px;
            position: relative;
            right: 25px;
          }

          .card-category-5 .per-card-2 .card-image::before {
            content: "";
            position: absolute;
            width: 0px;
            height: 0px;
            border: 12px solid #430ca5;
            top: -24px;
            left: 0px;
            border-left-color: transparent;
            border-top-color: transparent;
          }

          .card-category-5 .per-card-2 .card-image::after {
            content: "";
            position: absolute;
            width: 0px;
            height: 0px;
            border: 12px solid #430ca5;
            top: 104px;
            right: 0;
            border-right-color: transparent;
            border-bottom-color: transparent;
          }

          .card-category-5 .per-card-2 .card-image img {
            border-radius: 40px;
            height: 80px;
          }

          .card-category-5 .per-card-2 .card-image ul {
            padding: 0;
          }

          .card-category-5 .per-card-2 .card-image .per-name {
            font-size: 16px;
            color: #fff;
            margin: 5px 0px 0px 10px;
          }

          .card-category-5 .per-card-2 .card-image .per-position {
            font-size: 15px;
            color: #fff;
            margin: 15px 0px 0px 15px;
          }

          .card-category-5 .per-card-2 .card-image .card-btn {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 80px;
            right: 40px;
            border: 4px solid #fff;
            border-radius: 30px;
            background: #653cb7;
            cursor: pointer;

            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);

            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }

          .card-category-5 .per-card-2 .card-image .card-btn:hover {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
          }

          .card-category-5 .per-card-2 .card-image .card-btn span,
          .card-category-5 .per-card-2 .card-image .card-btn span::after {
            content: "";
            width: 23px;
            height: 3px;
            background: #fff;
            position: absolute;
            top: 18px;
            left: 8px;
          }

          .card-category-5 .per-card-2 .card-image .card-btn span::after {
            left: 0;
            top: 0;

            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
          }

          .card-category-5 .per-card-2 .card-content {
            padding: 40px 30px 20px;
            text-align: justify;
          }

          .card-category-5 .per-card-2 .card-content .card-text {
            line-height: 1.5;
            color: #585858;
            font-size: 15px;
          }

          .card-category-5 .per-card-2 .card-content .social-icons {
            text-align: center;
            padding-top: 20px;
          }

          .card-category-5 .per-card-2 .card-content .social-icons i {
            padding: 10px;
            font-size: 30px;
            color: #430ca5;
            cursor: pointer;
          }

          .card-category-5 .per-card-2 .card-content .social-icons i:hover {
            color: black;
          }

          .card-category-5 .per-card-3 {
            width: 400px;
            height: 240px;

            -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          }

          .card-category-5 .per-card-3:hover .card-content .social-icons {
            opacity: 1;
            height: 100%;
          }

          .card-category-5 .per-card-3:hover .social-icons .card-btn {
            bottom: 0px !important;

            -webkit-animation: down-btn 1.5s;
            -moz-animation: down-btn 1.5s;
            -o-animation: down-btn 1.5s;
            animation: down-btn 1.5s;
          }

          .card-category-5 .per-card-3 .card-image {
            position: relative;
            background: #009688;
            padding-left: 20px;
            text-align: left;
          }

          .card-category-5 .per-card-3 .card-image img {
            height: 90px;
            border-radius: 50px;
            padding: 5px;
            margin-right: 10px;
          }

          .card-category-5 .per-card-3 .card-image .per-name {
            position: absolute;
            top: 40%;
            text-transform: uppercase;
            letter-spacing: 7px;
            font-size: 20px;
            color: #fff;
          }

          .card-category-5 .per-card-3 .card-content {
            text-align: center;
            padding: 20px;
            position: relative;
          }

          .card-category-5 .per-card-3 .card-content .per-position {
            font-size: 22px;
            text-transform: uppercase;
            letter-spacing: 4px;
            color: #009688;
          }

          .card-category-5 .per-card-3 .card-content .card-text {
            padding: 10px;
          }

          .card-category-5 .per-card-3 .card-content .card-text span {
            font-size: 13px;
            color: #007368;
          }

          .card-category-5 .per-card-3 .card-content .social-icons {
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
            opacity: 0;
            height: 0%;
            background: #009688;
            border-top: 1px solid #5eada5;
            cursor: pointer;

            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
          }

          .card-category-5 .per-card-3 .card-content .social-icons i {
            margin: 15px;
            font-size: 30px;
            color: #fff;
            cursor: pointer;

            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }

          .card-category-5 .per-card-3 .card-content .social-icons i:hover {
            -webkit-transform: scale(2);
            -moz-transform: scale(2);
            -o-transform: scale(2);
            transform: scale(2);
          }

          .card-category-5 .per-card-3 .card-content .social-icons .card-btn {
            position: absolute;
            bottom: 10px;
            text-align: center;
            width: 100%;
          }

          .card-category-5
            .per-card-3
            .card-content
            .social-icons
            .card-btn
            button {
            cursor: pointer;
            width: 100%;
            padding: 15px;
            font-size: 18px;
            color: #fff;
            background: #03695f;
            border: none;
          }

          @media only screen and (max-width: 480px) {
            .io-card-1 .card-content .card-text {
              display: none;
            }

            .io-card-1 .card-link {
              top: 85px;
            }

        
           
           
            .card-category-4 .sp-card-2 {
              width: 300px;
            }

          
        
        
         

            .card-category-5 .per-card-2 {
              margin: 20px 40px;
            }

           
          }

          @media only screen and (max-width: 360px) {
            .ioverlay-card .card-content {
              top: 10px;
            }

            .io-card-1 .card-link {
              top: 70px;
            }

            .card-category-5 .per-card-2 {
              width: 275px;
            }

            .card-category-5 .per-card-2 .card-image {
              width: 295px;
            }

            .card-category-5 .per-card-3 {
              width: 320px;
              height: 254px;
            }
          }
        `}
      </style>
    </div>
  );
};
