
import axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_API;
const instance = axios.create({ baseURL });

instance.defaults.headers.common['Authorization'] = `Bearer `;

instance.defaults.headers.post['Content-Type'] = 'application/json';


export default instance;